import { useContext, useEffect, useRef, useState } from "react";
import { Dialog, DialogTitle, DialogContent, FormControl, Select, MenuItem, Typography, IconButton, OutlinedInput } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";
import VolumeUpIcon from "@mui/icons-material/VolumeUp";
import { DataUser } from "../../context/DataUser";
import styles from "../../css/ModalConfig.module.css";
import { TestAudioRecorder } from "./TestAudioRecorder";

export const ModalConfig = ({
  openConfig,
  setOpenConfig,
}) => {
  const { deviceInput, setDeviceInput, deviceOutput, setDeviceOutput, setStream } =
    useContext(DataUser);
  console.log("deviceOutput del modal",deviceOutput);
  const [hasPermission, setHasPermission] = useState(false);
  const [devicesInput, setDevicesInput] = useState([]);
  const [devicesOutput, setDevicesOutput] = useState([]);
  console.log("devicesOutput modal",devicesOutput);

  useEffect(() => {
    const checkMicrophonePermission = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true });
        setHasPermission(true);
        handleGetDevices();
      } catch (error) {
        setHasPermission(false);
        console.error('Error accessing microphone:', error);
      }
    };

    if (openConfig) {
      checkMicrophonePermission();
    }
  }, [openConfig]);

  const handleGetDevices = () => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const devicesInput = devices.filter((device) => device.kind === "audioinput");
      setDevicesInput(devicesInput);
      const deviceInputHeader = devicesInput.find((device) => device.deviceId === (deviceInput?.deviceId ? deviceInput.deviceId : "default"));
      setDeviceInput(deviceInputHeader);
      
      const devicesOutput = devices.filter((device) => device.kind === "audiooutput");
      setDevicesOutput(devicesOutput);
      // Modificación para usar el dispositivo de salida seleccionado si existe
      const deviceOutputHeader = devicesOutput.find((device) => device.deviceId === (deviceOutput?.deviceId ? deviceOutput.deviceId : "default"));
      setDeviceOutput(deviceOutputHeader);
      console.log("deviceOutput modal", deviceOutputHeader);
    });
  };

  const handleSelectDevice = async (device) => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const audioInputDevices = devices.filter(
        (d) => d.kind === 'audioinput' && d.deviceId === device.deviceId
      );

      if (audioInputDevices.length > 0) {
        const selectedDevice = audioInputDevices[0];
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: { deviceId: selectedDevice.deviceId },
        });

        setStream(stream);
        stream.getTracks().forEach((track) => track.stop());
        setDeviceInput(device);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleSelectOutput = (device) => {
    const audio = new Audio();
    if ('sinkId' in audio) {
      audio.setSinkId(device.deviceId)
        .then(() => {
          setDeviceOutput(device);
          console.log("setDeviceOutput",device);
          console.log("deviceOutput seleccionado",device);
        })
        .catch((error) => {
          console.error("error setting audio output device", error);
        });
    } else {
      console.error("Audio output selection not supported");
    }
  };

  const playSoundOutput = () => {
    const audio = new Audio();
    if ('sinkId' in audio) {
      audio.src = "/audio/Free_Test_Data_100KB_MP3.mp3";
      audio.setSinkId(deviceOutput.deviceId)

        .then(() => {
          audio.play();
        })
        .catch((error) => {
          console.error("error setting audio output device", error);
        });
    } else {
      console.error("Audio output selection not supported");
    }
  };

  const volumeTesterRef = useRef(null);

  const playTestSoundViaEnter = (e) => {
    if (e.key === "Enter") {
      playSoundOutput();
    }
  };

  useEffect(() => {
    volumeTesterRef?.current?.addEventListener("keydown", playTestSoundViaEnter);
    return () => {
      volumeTesterRef?.current?.removeEventListener("keydown", playTestSoundViaEnter);
    };
  });

  return (
    <Dialog
      open={openConfig}
      onClose={() => setOpenConfig(false)}
    >
      {hasPermission ? (
        <>
          <DialogTitle
            sx={{
              color: "white",
              backgroundColor: "#28262E",
              display: "flex",
              alignItems: "center",
            }}
          >
            <SettingsIcon fontSize="large" />
            <Typography
              id="alert-dialog-title"
              aria-label="Configuration microphone and output device"
              tabIndex={-1}
              sx={{ color: "white", fontWeight: "bold", fontSize: "2.5rem" }}
            >
              Configuration
            </Typography>
            <IconButton
              tabIndex={0}
              aria-label="close"
              onClick={() => setOpenConfig(false)}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent
            sx={{
              backgroundColor: "#28262E",
              maxWidth: "500px",
              width: "100%",
            }}
          >
            <Typography
              aria-label="Microphone selection"
              variant="h6"
              sx={{ color: "white" }}
            >
              Microphone
            </Typography>
            <FormControl fullWidth>
              <div className={styles.select}>
                <Select
                  autoFocus
                  labelId="mic-label"
                  aria-label="Sound Configuration, Select microphone"
                  id="mic"
                  input={<OutlinedInput />}
                  sx={{
                    textAlign: "initial",
                    color: "white",
                    backgroundColor: "#28262E",
                    width: "100%",
                    fieldset: {
                      borderColor: "white",
                      color: "white",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "white",
                        color: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "white",
                    },
                    "& .MuiSelect-root": {
                      color: "white",
                    },
                    "& .MuiMenuItem-root": {
                      color: "white",
                    },
                  }}
                  value={deviceInput}
                  onChange={(event) => handleSelectDevice(event.target.value)}
                >
                  {devicesInput.map((device) => (
                    <MenuItem key={device.deviceId} value={device}>
                      {device.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>
              <span aria-hidden="true" className={styles.message_check}>
                Check your microphone
              </span>
            </FormControl>
            <TestAudioRecorder />
            <Typography
              tabIndex={-1}
              aria-label="Output device selection"
              variant="h6"
              sx={{ color: "white" }}
            >
              Output Device
            </Typography>
            <FormControl fullWidth>
              <div className={styles.select}>
                <Select
                  labelId="audio-label"
                  id="audio"
                  input={<OutlinedInput />}
                  sx={{
                    textAlign: "initial",
                    color: "white",
                    backgroundColor: "#28262E",
                    width: "100%",
                    fieldset: {
                      borderColor: "white",
                      color: "white",
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "white",
                        color: "white",
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "white",
                      },
                    },
                    "& .MuiInputLabel-root": {
                      color: "white",
                    },
                    "& .MuiSelect-root": {
                      color: "white",
                    },
                    "& .MuiMenuItem-root": {
                      color: "white",
                    },
                  }}
                  value={deviceOutput}
                  onChange={(event) => {
                    handleSelectOutput(event.target.value);
                  }}
                >
                  {devicesOutput.map((device) => (
                    <MenuItem key={device.deviceId} value={device}>
                      {device.label}
                    </MenuItem>
                  ))}
                </Select>
                <div
                  aria-hidden="true"
                  ref={volumeTesterRef}
                  aria-label="Testing output device, play sound"
                  onClick={playSoundOutput}
                >
                  <VolumeUpIcon
                    onClick={playSoundOutput}
                    className={styles.icon}
                    fontSize="large"
                    sx={{
                      color: "white",
                      marginLeft: "1rem",
                      cursor: "pointer",
                      "&:hover": {
                        color: "#F50057",
                      },
                    }}
                  />
                </div>
              </div>
              <span
                tabIndex={0}
                role="button"
                onClick={playSoundOutput}
                className={styles.message_check}
              >
                Check your output device
              </span>
            </FormControl>
          </DialogContent>
        </>
      ) : (
        <DialogTitle
          id="alert-dialog-title"
          aria-label="Microphone access denied"
          sx={{
            color: "white",
            backgroundColor: "#28262E",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            tabIndex={0}
            aria-label="Microphone access denied"
            sx={{ color: "white", fontWeight: "bold", fontSize: "2.5rem" }}
            autoFocus
          >
            You need to allow access to your microphone
          </Typography>
        </DialogTitle>
      )}
    </Dialog>
  );
};