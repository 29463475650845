import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import StopIcon from '@mui/icons-material/Stop';
import { Dialog, DialogContent, DialogContentText } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { DataDescriptor } from '../../context/DataDescriptor';
import styles from '../../css/Interpreter.module.css';
import { upladRecordingVideo } from '../../utils/audio/uploadS3';
import { playSegment } from '../../utils/time/timeActions';
import { DataUser } from '../../context/DataUser';
export const RecordingRegion = () => {
  const videoRef = useRef(null);
  const [isPlayingRecord, setIsPlayingRecord] = useState(false);
  const mediaRecorderRef = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [recordedBlobUrl, setRecordedBlobUrl] = useState(null);
  const [idSegment, setIdSegment] = useState(null);
  const {
    duration,
    currentRegion,
    websurfer,
    setIsPlaying
  } = useContext(DataDescriptor);
  const { deviceOutput } = useContext(DataUser);
  console.log("deviceOutput RecordingRegion",deviceOutput);
  const [openCounter, setOpenCounter] = useState(false);
  console.log("RecordingRegion");
  useEffect(() => {
    if (isRecording) {
      const streamVideo = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({ video: true });
          videoRef.current.srcObject = stream;
          mediaRecorderRef.current = new MediaRecorder(stream);
          mediaRecorderRef.current.start();

          videoRef.current.play();

          mediaRecorderRef.current.addEventListener('dataavailable', handleDataAvailable);

          mediaRecorderRef.current.addEventListener('stop', () => {
            setIsRecording(false);
          });
        } catch (error) {
          console.error('Error accessing camera:', error);
        }
      };
      streamVideo();
    }
  }, [isRecording]);

  useEffect(() => {
    if (currentRegion && !isRecording) {
     if (currentRegion.data.videorecord) {      
        stopRecording();
        setRecordedBlobUrl(currentRegion.data.videorecord);
        videoRef.current.load(currentRegion.data.videorecord);
      } else {
        setRecordedBlobUrl(null);
      }
    }
  }, [currentRegion]);

  const togglePlay = async () => {
    console.log("togglePlay function called"); // Log to check if the function is called
    websurfer.current.setVolume(1);
    if (videoRef.current) {
      if (isPlayingRecord) {
        videoRef.current.pause();
      } else {
        // Create an audio context
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        const source = audioContext.createMediaElementSource(videoRef.current);
  
        // Create a destination node for the specified deviceOutput
        const audioDestination = audioContext.createMediaStreamDestination();
        source.connect(audioDestination);
  
        // Set the output device
        const audioOutput = new Audio();
        audioOutput.srcObject = audioDestination.stream;
        audioOutput.setSinkId(deviceOutput.deviceId).then(() => {
          videoRef.current.play();
        }).catch(error => {
          console.error('Error setting audio output device:', error);
        });
      }
      setIsPlayingRecord(!isPlayingRecord);
    }
  };
  const handleDataAvailable = async(event) => {
    const blobRecord = new Blob([event.data], { type: 'video/webm' });
    const boblURLRecord = URL.createObjectURL(blobRecord);
    const url=await upladRecordingVideo(currentRegion, idSegment, blobRecord);
    currentRegion.update({
        data: {
          ...currentRegion.data,
          videorecord: url,
        },
      });
  }

 

  useEffect(() => {
    if (isRecording) {
      let timer = setTimeout(() => {
        stopRecording(true);
      }, duration * 1000);
      return () => clearTimeout(timer);
    }
  }, [isRecording]);

  /*const startRecording = () => {    
    setIdSegment(currentRegion.data.id);
    playSegment(currentRegion, websurfer, setIsPlaying);
    const delay =(currentRegion.data.start-currentRegion.start )*1000;
    console.log("currentRegion.data.start: ", currentRegion.data.start,"currentRegion.start: ", "Tiempo: ", currentRegion.data.start-currentRegion.start);
    setTimeout(() => {
      setIsRecording(true);
      setOpenCounter(true);
      setTimeout(() => {
        setOpenCounter(false);
      }, 1000);
    },delay);
  };
*/
  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
    setIsRecording(false);
  };

  const stopVideo = () => {
    if (videoRef.current) {
      
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
    setIsPlayingRecord(false);
  };
  const videoStyles = {
    width: '100%',
    height: '55vh',
    backgroundColor: 'black',
    borderRadius: '10px',
  };

  return (
    <div style={{ position: 'absolute', left: '20px', width: '50%' }}>
      {(recordedBlobUrl && !isRecording)? (
        <>
        <h1>Video recorded</h1>
          <video
            style={videoStyles}
            src={recordedBlobUrl}
            muted
            ref={videoRef}
          /></>
        ) : (<video ref={videoRef} style={videoStyles} muted />)
      }
       <Dialog
          id='modal-counter'
          open={openCounter}
          fullWidth={true}
          maxWidth={'sm'}
        >
          <DialogContent
            sx={{ color: 'white', backgroundColor: '#28262E' }}
          >
            <DialogContentText
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#C75E43',
              }}
            >
              <span style={{ fontSize: '48px', color: 'white' }}>
              Begin to interpret
              </span>
            </DialogContentText>
          </DialogContent>
        </Dialog>
      <div className={styles.recordingButtonContainer}>
        {isRecording ? (
          <button onClick={stopRecording}>
            <StopIcon style={{ color: 'white', fontSize: '60px' }} />
          </button>
        ) : (
          <>
          {recordedBlobUrl && (
            <button onClick={stopVideo}>
                <StopIcon style={{ color: 'white', fontSize: '60px' }} />
              </button>              
          )}
            <button className={styles.recordingButton} onClick={startRecording}>
              <RadioButtonCheckedIcon style={{ color: 'red', fontSize: '60px' }} />
            </button>
            {recordedBlobUrl && (
              <button onClick={togglePlay}>
                {isPlayingRecord ? (
                  <PauseCircleIcon style={{ color: 'white', fontSize: '60px' }} />
                ) : (
                  <PlayCircleIcon style={{ color: 'white', fontSize: '60px' }} />
                )}
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};



