import SendIcon from "@mui/icons-material/Send";
import { Alert, CircularProgress, Snackbar } from "@mui/material";
import React, { useContext, useState,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { DataDescriptor } from "../context/DataDescriptor";
import { DataUser } from "../context/DataUser";
import styles from "../css/Send.module.css";
import { generateMixAudio } from "../utils/audio/generateMix";
import { getProject,projectsListFromDB,updateResourceDeliverables } from "../utils/project/CRUD";
import { sendEmailNeedExternalAudio } from "../utils/project/sendEmails";
import { ModalCheckSend } from "./ModalCheckSend";

export const Send = () => {
  const { segmentsComplete } = useContext(DataDescriptor);
  const { updateRole, currentProject } = useContext(DataUser);
  const [openToast, setOpenToast] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const { getProyects } = useContext(DataUser);
  const [rol, setRol] = useState("")
  const hasVoiceOver = currentProject?.users?.filter((user) => user?.role === 'AN_D');
  const [openModalCheck, setOpenModalCheck] = useState(false);
  const navigate = useNavigate();
const projectname=currentProject.name.toLowerCase();
const [TalentIdManage, setTalentIdManage]=useState([])
console.log("TalentIdManage",TalentIdManage);
console.log(projectname);
  const handleClickOpenModalCheck = () => setOpenModalCheck(true);
  const [tempLinks, setTempLinks] = useState([]); // Declare tempLinks as a state variable
console.log("temp",tempLinks);


useEffect(() => {
  if (currentProject?.frommanage === "Yes") {
    const fetchProjectsList = async () => {
      try {
        const projects = await projectsListFromDB();
        const textResponse = JSON.stringify(projects);

        try {
          const projects = JSON.parse(textResponse);
          

          const allResources = [];
          projects.forEach(project => {
            if (project.services) {
              project.services.forEach(service => {
                if (service.resources) {
                  allResources.push(...service.resources);
                }
              });
            }
          });

         

          const filteredResources = allResources.filter(resource => 
            resource.name && resource.name.toLowerCase() === projectname
          );

         

          const allTalents = filteredResources.flatMap(resource => 
            (resource.talents || []).map(talent => ({
              tpr_id: talent.tpr_id,
              role: talent.role,
              temp_link: resource.temp_link
            }))
          );
          setTalentIdManage(allTalents);
       

          const newTempLinks = filteredResources.reduce((acc, resource) => {
            if (resource.temp_link) {
              console.log(`Found temp_link: ${resource.temp_link}`);
              acc.push(resource);
            }
            return acc;
          }, []);
     
         

          const projectLinks = filteredResources.reduce((acc, resource) => {
          

            if (resource.path_lower) {
             

              if (resource.path_lower.includes(`/${projectname}/`)) {
               
                acc.push(resource.shared_link);
              }
            }

            if (resource.name && resource.name.toLowerCase() === projectname) {
            
              acc.push(resource.temp_link);
            }

            return acc;
          }, []);
          setTempLinks(projectLinks);
        

        } catch (jsonError) {
          throw new Error("La respuesta no es un JSON válido");
        }

      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjectsList();
  }
}, [currentProject]);

// Filter tempLinks where path_lower includes the project name
// Helper function to recursively extract links
const extractLinks = (folders, projectname) => {
  let links = [];

  // Ensure folders is an array
  if (!Array.isArray(folders)) {
    console.error("Expected folders to be an array, but got:", folders);
    return links;
  }

  folders.forEach(folder => {
    if (folder.path_lower && folder.path_lower.includes(projectname)) {
      links.push({
        shared_link: folder.shared_link,
        path_lower: folder.path_lower
      });
    }
    if (folder.subfolders && Array.isArray(folder.subfolders) && folder.subfolders.length > 0) {
      links = links.concat(extractLinks(folder.subfolders, projectname));
    }
  });

  return links;
};

// Use the helper function to filter and extract links
const filteredTempLinks = Object.values(tempLinks).flatMap(folderGroup => {
  // Check if folderGroup is an object and extract its values
  if (typeof folderGroup === 'object' && !Array.isArray(folderGroup)) {
    return Object.values(folderGroup).flatMap(folders => extractLinks(folders, projectname));
  }
  // If folderGroup is already an array, process it directly
  if (Array.isArray(folderGroup)) {
    return extractLinks(folderGroup, projectname);
  }
  console.error("Unexpected folderGroup structure:", folderGroup);
  return [];
});

console.log("Filtered Temp Links with Path:", filteredTempLinks);
const extractRequestLinks = (data) => {
  const result = [];

  const extractLinksRecursive = (items, parentPath = '') => {
    items.forEach(item => {
      // Revisa si el item tiene requestlinks
      if (item.requestlinks && Array.isArray(item.requestlinks)) {
        item.requestlinks.forEach(requestLinkObj => {
          // Revisa si el requestLink contiene links
          if (requestLinkObj.requestLink && Array.isArray(requestLinkObj.requestLink.link)) {
            requestLinkObj.requestLink.link.forEach(linkItem => {
              // Si hay un file_request_url en el nivel actual
              if (linkItem.file_request_url) {
                result.push({
                  path: `${parentPath}${linkItem.path_display || ''}`.replace(/\/\//g, '/'),
                  file_request_url: linkItem.file_request_url
                });
              }

              // Si hay subfolders en el link, recursivamente extrae links de esos subfolders
              if (Array.isArray(linkItem.subfolders) && linkItem.subfolders.length > 0) {
                extractLinksRecursive(linkItem.subfolders, `${parentPath}${linkItem.path_display || ''}/`.replace(/\/\//g, '/'));
              }
            });
          }
        });
      }

      // Si el item tiene un file_request_url en el nivel actual
      if (item.file_request_url) {
        result.push({
          path: `${parentPath}${item.path_display || ''}`.replace(/\/\//g, '/'),
          file_request_url: item.file_request_url
        });
      }

      // Si el item tiene subfolders, aplica recursividad en esos subfolders
      if (item.subfolders && Array.isArray(item.subfolders)) {
        extractLinksRecursive(item.subfolders, `${parentPath}${item.path_display || ''}/`.replace(/\/\//g, '/'));
      }
    });
  };

  extractLinksRecursive(data);
  return result;
};

// Uso
const requestLinks = extractRequestLinks(tempLinks);
console.log("Request Links with Paths:", requestLinks);
  const sendProject = async () => {
    setOpenToast(true);
    setDisabled(true);
    if (!currentProject.rol?.role.includes("QC") && !window.location.href.includes("quality-control")) {
      if (window.location.href.includes("audio-descriptor")) {
        setRol("Quality Control")
        await updateRole("QC");
       requestLinks.forEach(link => {
        if (link.path.includes('Descriptor') && link.path.includes(projectname)) {
            window.open(link.file_request_url, '_blank');
        }
    });
        if (currentProject?.frommanage === "Yes") {
        console.log("TalentIdManage:", TalentIdManage); // Verifica el contenido
        const descriptorTalent = TalentIdManage.find(talent => talent.role === 'Descriptor');
        const descriptorLink = filteredTempLinks.find(link => link.path_lower.includes("descriptor"));
        console.log("Descriptor Talent:", descriptorTalent); //
        const updatedDeliverables = descriptorTalent ? {
          tpr_id: descriptorTalent.tpr_id,
          deliverables: {
            '0': {
              link: descriptorLink ? descriptorLink.shared_link : null, // Include the link if found
              name: "Descriptor",
              state: 'in review'
            }
          }
        } : {};
        
        // Verifica si el objeto se ha creado correctamente
        console.log("Datos enviados a updateResourceDeliverables:", updatedDeliverables);
            try {
              // Llamada a la función para actualizar los deliverables
              await updateResourceDeliverables({ updatedDeliverables });
              console.log("Deliverables actualizados:", updatedDeliverables);
            } catch (error) {
              console.error("Error al actualizar deliverables:", error);
            }
          }
                }
      else if (window.location.href.includes("/narrator")) {
        if (hasVoiceOver) {
          const resp = await checkNarratorVoiceOver()
          console.log("checkNarratorVoiceOver",resp);
          if (resp) {
            setRol("Audio Editor")
            await updateRole("AE");
            
      if (currentProject?.frommanage === "Yes") {
            console.log("TalentIdManage:", TalentIdManage); // Verifica el contenido
            const narratorTalent = TalentIdManage.find(talent => talent.role === 'Narrator');
            const narratorLink = filteredTempLinks.find(link => link.path_lower.includes("Narrator"));
            console.log("Narrator Talent:", narratorTalent); //
            const updatedDeliverables = narratorTalent ? {
              tpr_id: narratorTalent.tpr_id,
              deliverables: {
                '0': {
                  link: narratorLink ? narratorLink.shared_link : null, // Include the link if found
                  name: "Narrator",
                  state: 'in review'
                }
              }
            } : {};
            
            // Verifica si el objeto se ha creado correctamente
            console.log("Datos enviados a updateResourceDeliverables:", updatedDeliverables);
                try {
                  // Llamada a la función para actualizar los deliverables
                  await updateResourceDeliverables({ updatedDeliverables });
                  console.log("Deliverables actualizados:", updatedDeliverables);
                } catch (error) {
                  console.error("Error al actualizar deliverables:", error);
                }
              }
            if (currentProject.needExternalAudio) {
              sendEmailNeedExternalAudio(currentProject._id);
            } else {
              console.log(" se ejecuta generateMixAudio",currentProject._id);
              generateMixAudio(currentProject._id);
            }
          }else{
            setRol("Narrator await all narrators to finish")
          }
        }
       
      } else if (window.location.href.includes("/script-adapter/")) {
        setRol("Interpreter")
        await updateRole("INTERPRETER");
      } else if (window.location.href.includes("/interpreter/")) {
        setRol("Audio Editor")
        await updateRole("AE");
        generateMixAudio(currentProject._id);
      } else if (window.location.href.includes("narrator-dubbing")) {
        const resp = checkNarratorVoiceOver()

        if (resp) {
          setRol("Quality Control")
          await updateRole("QC2");
        } else {
          setRol("Narrator await all narrators to finish")
          await updateRole("AN_D");

        }
      }
    } else {
      setRol("Audio Descriptor")
      await updateRole("AD");
    }
    setTimeout(() => {
      setDisabled(false);
      setOpenToast(false);
      getProyects()
      navigate("/dashboard");
    }, 3000);
  };

  const checkNarratorVoiceOver = async () => {
    const { project } = await getProject(currentProject._id);
    const { captions } = project;
    const captionsWithoutAudio = captions.filter((caption) => !caption.audiorecord.initial)
console.log(captions);
    const captionsWithoutAudioToCorrect = captions.filter((caption) => caption.review.approved === false && caption.review.comment[0] !== "" && caption.audiorecord.initial === caption.audiorecord.preview);
    console.log("captionsWithoutAudio",captionsWithoutAudio);
    if (captionsWithoutAudio.length > 0 || captionsWithoutAudioToCorrect.length > 0) {
      setRol("Narrator await all narrators to finish")
      return false;
    } else {
      return true;
    }
  }
  return (
    <>
      <Snackbar
        open={openToast}
        autoHideDuration={3000}
        onClose={() => setOpenToast(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity={"success"} variant="filled">
          Sending to {rol}
        </Alert>
      </Snackbar>
      {disabled ?
        <button
          className={styles.button__send__disabled}
          disabled={disabled}
        >
          <CircularProgress aria-label='Loading' />
        </button>
        : (
          <button
            className={segmentsComplete ? styles.button__send : styles.button__send__disabled}
            onClick={() => handleClickOpenModalCheck()}
            disabled={!segmentsComplete}
            aria-label="Send Button"
            tabIndex="0"
          >
            <SendIcon sx={{ fontSize: 40 }} />
            <span>SEND</span>
          </button>
        )}

      <ModalCheckSend
        open={openModalCheck}
        setOpen={setOpenModalCheck}
        sendProject={sendProject}
        disabled={disabled}
      />

    </>
  );
};
