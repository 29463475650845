import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import GridViewIcon from "@mui/icons-material/GridView";
import { CircularProgress, TextField, IconButton } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { CardProject } from "../components/dashboard/CardProject";
import { ListProject } from "../components/dashboard/ListProject";
import { DataUser } from "../context/DataUser";
import styles from "../css/ProjectsList.module.css";
import SearchIcon from '@mui/icons-material/Search';


export const ProjectsList = ({ user, projects }) => {
  const [activeButton, setActiveButton] = useState("grid"); // Estado para rastrear el botón activo
  const { getProyects, pagination, rolFilter, page, setPage, stageFilter, loading, filter, setFilter } = useContext(DataUser);
  const [projectsList, setProjectsList] = useState([]);
  console.log("projectsList",projectsList);
  const [loadingPage, setLoadingPage] = useState(false);
  const [filterText, setFilterText] = useState(""); // State to hold filter text
  const [showSearchField, setShowSearchField] = useState(false); // State to control TextField visibility
console.log("filter",filter);

  const handleButtonClick = (button) => setActiveButton(button)

  useEffect(() => {setProjectsList(projects)}, [projects]);

  const filteredProjects = Array.from(new Set(projectsList.map(project => project.name)))
    .map(name => projectsList.find(project => project.name === name))
    .filter(project => project.name.toLowerCase().includes(filterText.toLowerCase()))
    .sort((a, b) => {
      const dateComparison = new Date(b.start_date) - new Date(a.start_date);
      if (dateComparison !== 0) return dateComparison;
      return a.name.localeCompare(b.name); // Secondary sort by name
    });
  const loadProjects = async (page) => {
    setLoadingPage(true);
    if (user?.roles.includes("PM")) {
      if (rolFilter && page !== 1) {
        await getProyects(page, false, rolFilter)
      } else if (rolFilter && page === 1) {
        await getProyects(page, true, rolFilter)
      } else if (!rolFilter && page !== 1) {
        await getProyects(page, false)
      } else if (!rolFilter && page === 1) {
        await getProyects(page, true)
      }
    } else if (user?.roles.includes("QC")) {
      if (page !== 1 && !stageFilter) {
        await getProyects(page, false, "QC")
      } else if (page === 1 && !stageFilter) {
        await getProyects(page, true, "QC")
      } else if (page !== 1 && stageFilter === "Active") {
        await getProyects(page, false)
      } else if (page === 1 && stageFilter === "Active") {
        await getProyects(page, true)
      } else if (page !== 1 && stageFilter) {
        await getProyects(page, false, "QC", stageFilter)
      } else if (page === 1 && stageFilter) {
        await getProyects(page, true, "QC", stageFilter)
      }
    } else {
      if (page !== 1 && !stageFilter) {
        await getProyects(page, false, null)
      } else if (page === 1 && !stageFilter) {
        await getProyects(page, true, null)
      } else if (page !== 1 && stageFilter) {
        await getProyects(page, false, null, stageFilter)
      } else if (page === 1 && stageFilter) {
        await getProyects(page, true, null, stageFilter)
      }
    }
    setLoadingPage(false);
  };

  const handleContainerScroll = (e) => {
    const container = e.target;
    const tolerance = 1;
    if (
      container.scrollHeight - container.scrollTop <= container.clientHeight + tolerance && !loadingPage && !loading
    ) {
      if (pagination?.totalPages > 0 && pagination?.totalPages > page) {
        setPage(page + 1);
        loadProjects(page + 1);
      }
    }
  };

  return (
    <div className={styles.container}>
      <header className={styles.header__Projects}>
      <h2>
  <FolderOpenIcon /> {filter ? filter : "All Projects in Progress"}
</h2>
        <div className={styles.filter__container}>
          <IconButton aria-label="search" onClick={() => setShowSearchField(!showSearchField)} sx={{ color: 'white' }}>
            <SearchIcon />
          </IconButton>
          {showSearchField && (
            <TextField
              variant="outlined"
              placeholder="Filter projects..."
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
              className={styles.filterInput}
              InputProps={{
                style: {height: '2rem', marginTop: '0.2rem', color: 'white'} // Maintain the font size
              }}
            />
          )}
         {/* <div className={styles.change__view}>
            <button
              className={activeButton === "grid" ? styles.activeButton : ""}
              onClick={() => handleButtonClick("grid")}
              aria-label="cards view"
            >
              <GridViewIcon />
            </button>
             <button
              className={activeButton === "list" ? styles.activeButton : ""}
              onClick={() => handleButtonClick("list")}
              aria-label="table view"
            >
              <FormatListBulletedIcon />
            </button>
          </div>*/}
        </div>
      </header>
      {activeButton === "grid" ? (
        <>
          {user?.roles && (
            <>
              <div className={styles.container__projectList} onScroll={handleContainerScroll}>
                {(projectsList?.length === 0 && !loading) && (
                  <div className={styles.container__noProjects}>
                    <h3>No projects</h3>
                  </div>
                )}
                {(!loading) && ( filteredProjects.map((project, index) => <CardProject key={index} project={project} />))}
                {loading && <div className={styles.container_loading}><CircularProgress /></div>}
                <div aria-live="assertive" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '200px', width: '100%' }}>
                  {(projectsList?.length > 0 && loadingPage) && (<CircularProgress aria-label='Loading projects...' aria-live="assertive" />)}
                </div>
              </div>
            </>
          )}
        </>
      ) : (
        <div className={styles.container__projectListTable}>
          <ListProject projects={filteredProjects} user={user} />
        </div>
      )}
    </div>
  );
};
