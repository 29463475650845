import React, { useContext, useEffect, useState } from "react";
import { Time } from "../components/Time";
import { TextFields } from "../components/describer/TextFields";
import TextField from "../components/scriptAdapter/TextField";
import { DataDescriptor } from "../context/DataDescriptor";
import { DataUser } from "../context/DataUser";
import styles from "../css/DescriptorActions.module.css";
import ReplayIcon from "@mui/icons-material/Replay";
import { getProject } from "../utils/project/CRUD";

export const DescriptorActions = () => {
  const { currentRegion, resized } = useContext(DataDescriptor);
  const { currentProject } = useContext(DataUser);

  const [completedSegments, setCompletedSegments] = useState(0);
  const [totalSegments, setTotalSegments] = useState(0);
  console.log("completedSegments",completedSegments);
  console.log("totalSegments",totalSegments);

  useEffect(() => {
    if (currentProject) {
      calculateSegments(currentProject);
    }
  }, [currentProject]);

  const calculateSegments = async (projectWrapper) => {
    if (!projectWrapper) {
      console.error("Invalid project data:", projectWrapper);
      return;
    }
  
    // Llama al endpoint para obtener el proyecto completo
    const response = await getProject(projectWrapper._id || projectWrapper.project?._id);
  
    // Asegúrate de que la respuesta contenga el proyecto
    if (!response || !response.project) {
      console.error("Missing required project data:", response);
      return;
    }
  
    const projectInfo = response.project; // Extrae el objeto `project`
    const { segments, segmentsSign, rol } = projectInfo;
  
    if (!segments || !rol) {
      console.error("Missing required project data inside `project`:", projectInfo);
      return;
    }
  
    let completed = 0;
  
    if (rol.role === "SCRIPT_ADAPTER") {
      completed = segmentsSign.filter(
        (segment) => segment.script && segment.script !== ""
      ).length;
      setCompletedSegments(completed);
      setTotalSegments(segmentsSign.length);
    } else if (rol.role === "AD") {
      completed = segments.filter((segment) => {
        const { initial, preview } = segment.description;
        return initial !== preview || segment.review.approved === true;
      }).length;
      setCompletedSegments(completed);
      setTotalSegments(segments.length);
    } else if (rol.role === "AN") {
      completed = segments.filter((segment) => {
        const { initial, preview } = segment.audiorecord;
        return initial !== preview || segment.review.approved === true;
      }).length;
      setCompletedSegments(completed);
      setTotalSegments(segments.length);
    } else if (rol.role.includes("QC")) {
      const missing = segments.filter(
        (segment) =>
          ((!segment.review.comment[0] && !segment.review.approved) ||
            (!segment.review.approved &&
              segment.review.comment[0] === segment.review.comment[1])) &&
          !segment.isDisabled
      );
      completed = segments.length - missing.length;
      setCompletedSegments(completed);
      setTotalSegments(segments.length);
    }
  };
  

  return (
    <div
      className={styles.container__descriptorActions}
      style={{ width: resized ? "25%" : "40%" }}
    >
      {currentRegion ? (
        <>
          <Time />
          {currentProject.service === "sign-language-caption" ? (
            <TextField />
          ) : (
            <TextFields />
          )}
       <div style={{ display: 'flex', alignItems: 'center' ,justifyContent:'end',marginTop:'5px'}}>
            <h1 style={{ marginRight: '10px' }}>{`${completedSegments}/${totalSegments}`}</h1>
            <ReplayIcon 
              className={completedSegments === totalSegments ? styles.replayIconDisabled : styles.replayIcon}
              onClick={() => {
                if (completedSegments !== totalSegments) {
                  calculateSegments(currentProject);
                }
              }} 
            />
          </div>
        </>
      ) : (
        <div className={styles.select__segment}>
          <img src="/assets/img/SelectSegment.png" alt="Select a Segment" />
          <span>Select a Segment</span>
        </div>
      )}
    </div>
  );
};
